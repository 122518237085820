/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 *
 * You can delete this file if you're not using it
 */

exports.onInitialClientRender = () => {
  const host =
    window.location.hostname === "www.wafdbank.com" ? "https://chat.wafdbank.com" : "https://chat-dev.pikestreet.io";
  function handleChatScriptLoad() {
    console.log("AWS chat 2nd script loaded");
    var loaderOpts = {
      baseUrl: host,
      shouldLoadMinDeps: true
    };
    var loader = new ChatBotUiLoader.IframeLoader(loaderOpts);
    var chatbotUiConfig = {};
    loader.load(chatbotUiConfig).catch(function (error) {
      console.error(error);
    });
  }

  const isExistingSession = JSON.parse(sessionStorage.getItem("isExistingSession"))
  
  setTimeout(() => {
    console.log("Delayed for 15 seconds.");
    let script = document.createElement("script");
    script.src = `${host}/lex-web-ui-loader.min.js`;
    script.defer = true;
    script.onload = handleChatScriptLoad;
    document.body.appendChild(script);
  }, isExistingSession ? 0 : 15000);
};
